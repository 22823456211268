<template>
  <Header></Header>
  <div class="home">
    home
  </div>
</template>

<script>
import Header from '../components/Header.vue'
export default {
  name: 'HomeView',
  components: {
    Header
  }
}
</script>
