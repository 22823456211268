<template>
<div class="header">
            <a class="header_title" href="">Udemy</a>
            <div class="categories">
                <button class="header_btn categories_btn" type="button">Categories</button>
                <div class="sub_menu">
                    <ul>
                        <li class="item">
                            <span class="item_name">Twitter</span>
                            <img class="next_icon" src="img/next.png" alt="">
                        </li>
                        <li class="item">
                            <span class="item_name">Instagram</span>
                            <img class="next_icon" src="img/next.png" alt="">
                        </li>
                        <li class="item">
                            <span class="item_name">Dribble</span>
                            <img class="next_icon" src="img/next.png" alt="">
                        </li>
                        <li class="item">
                            <span class="item_name">Telegram</span>
                            <img class="next_icon" src="img/next.png" alt="">
                        </li>
                    </ul>
                </div>
            </div>
            <div class="search">
                <form class="search_form" action="" type="text">
                    <label for="search_labal"></label>
                    <input class="search_input" type="text" placeholder="Search for anything">
                </form>
            </div>
            <button class="header_btn login_btn" type="button">Log in</button>
            <button class="header_btn signup_btn" type="button">Sign up</button>
        </div>
</template>

<script>
export default {
    name:'Header'
}
</script>

<style>


* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: Vazir;
    /* font-family: Shabnam; */
    /* font-family: 'Josefin Sans', sans-serif !important; */
}

.header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .08), 0 4px 12px rgba(0, 0, 0, .08);
}

.header_title {
    margin: 0 10px;
}

.header_btn {
    background-color: transparent;
    align-items: center;
    border: none;
    cursor: pointer;
    display: inline-flex;
    min-width: 5rem;
    /* padding: 0 1.2rem; */
    justify-content: center;
    height: 2.5rem;
}

.categories {
    position: relative;
    /* height: 4rem; */
    display: flex;
    align-items: center;
    padding: 0 20px 0 10px;
    margin: 0 0 0 10px;
}

.login_btn {
    color: black;
    background-color: transparent;
    border: 1px solid #2d2f31;
    margin-right: 0.5rem;
}

.signup_btn {
    background-color: black;
    border: 1px solid #2d2f31;
    color: white;
}

.search {
    padding-left: 1.5rem;
    width: 16rem;
    margin: 0 10px;
    height: 2.5rem;
    border: 1px solid #1c1d1f;
    border-radius: 9999px;
    background-color: #f7f9fa;
}

.search_form {
    display: flex;
    align-items: center;
    height: 100%;
}

.search_input {
    background-color: #f7f9fa;
    border: 0;
}

.sub_menu {
    display: none;
    list-style: none;
    position: absolute;
    background: #fff;
    height: auto;
    width: 170px;
    top: 100%;
    padding: 0.5rem 0;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.08);
    transition: 0.2s ease;
}

.categories:hover {
    height: 4.5rem;
}

.categories:hover .sub_menu {
    display: block;
    position: absolute;
}

.sub_menu ul {
    padding: 0 !important;
    margin: 0;
}

main {
    padding: 10px 0;
}

.item {
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.categories:hover .categories_btn,
.item:hover {
    color: #5624d0;
}

.item_name {
    flex: 1;
}

.next_icon {
    width: 12px;
}

.login_btn {
    margin-left: 20px;
}

.signup_btn {
    margin-right: 20px;
}


</style>