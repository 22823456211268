<template>
  <!-- <Header></Header> -->
  <div class="home">
    <input type="email" name="email" placeholder="email" v-model="email" />
    <button @click="register()">Register</button>
  </div>
</template>


<script>
// import Header from '../components/Header.vue'
import Authentication from '@/services/Authentication';
export default {
  data() {
    return {
      email: ''
    }

  },
  name: 'Register',
  methods: {
    async register() {
      const response = await Authentication.register({
        email: this.email
      })
      console.log(response.data);
      console.log(this.email)
    }
  }

}
</script>
